<template>
  <div>
    <p class="compont_p">会员基本信息-资料审核通过后只能进行密码修改</p>
    <div class="form_wrap">
      <el-form label-width="100px" :model="form" :rules="rules" ref="form">
        <el-form-item label="会员账号：" prop="username">
          <el-input v-model="form.username" :disabled="disable" placeholder="请输入会员账号"></el-input>
        </el-form-item>
        <el-form-item label="会员类型：" prop="jylx">
          <el-select v-model="form.jylx" placeholder="请选择会员类型" :disabled="disable">
            <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="会员密码：" prop="userpass">
          <el-input v-model="form.userpass" type="password" placeholder="请输入会员密码"></el-input>
        </el-form-item>
        <el-form-item v-if="disable">
          <div class="btn flex_c_c" @click="handleSave">修改密码</div>
        </el-form-item>
      </el-form>
    </div>
    <p class="compont_p">采购人基本信息</p>
    <div class="form_wrap">
      <el-form label-width="100px" :model="form">
        <el-form-item label="采购人姓名：">
          <el-input v-model="form.lxr" :disabled="disable" placeholder="请输入采购人姓名"></el-input>
        </el-form-item>
        <el-form-item label="采购人手机：">
          <el-input v-model="form.lxdh" :disabled="disable" placeholder="请输入采购人手机"></el-input>
        </el-form-item>
        <el-form-item label="采购人邮箱：">
          <el-input v-model="form.lxyx" :disabled="disable" placeholder="请输入采购人邮箱"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <p class="compont_p">单位基本信息</p>
    <div class="form_wrap">
      <el-form label-width="100px" :model="form">
        <el-form-item label="单位名称：">
          <el-input v-model="form.dwmc" :disabled="disable" placeholder="请输入单位名称"></el-input>
        </el-form-item>
        <el-form-item label="经营内容：">
          <el-checkbox :disabled="disable" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
          <el-checkbox-group v-model="form.jynr" :disabled="disable" @change="handleCheckedCitiesChange">
            <el-checkbox v-for="item in checkList" :key="item.id" :label="item.id">{{ item.title }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="详细地址：">
          <div class="flex_row">
            <el-cascader class="mr_10" :disabled="disable" v-model="value" :options="options1" :props="{ expandTrigger: 'hover', label: 'text' }"></el-cascader>
            <el-input v-model="form.dwdz" :disabled="disable" placeholder="请输入详细地址"></el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <p style="color: #ff4c4c">* 审核通过后不能进行资料修改，只允许修改密码</p>
        </el-form-item>
        <el-form-item v-if="!disable">
          <div class="btn flex_c_c" @click="handleSave">提交资料</div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import md5 from "md5";

export default {
  data() {
    return {
      form: {
        username: "",
        userpass: "",
        jylx: "",
        lxr: "",
        lxdh: "",
        lxyx: "",
        dwmc: "",
        jynr: [],
        sheng: "",
        shi: "",
        quxian: "",
        dwdz: "",
      },
      disable: false, //审核状态 1允许修改，>1禁止修改
      checkAll: false,
      isIndeterminate: true,
      options: [],
      value: "",
      checkList: [],
      options1: [],
      rules: {
        username: [{ required: true, message: "请输入会员账号", trigger: "blur" }],
        jylx: [{ required: true, message: "请选择会员类型", trigger: "change" }],
        userpass: [{ required: true, message: "请输入会员密码", trigger: "blur" }],
      },
    };
  },
  created() {
    this.$api("account.getInfo").then((res) => {
      this.disable = res.data.shzt > 1 ? true : false;
      this.value = [res.data.sheng, res.data.shi, res.data.quxian];
      this.form = res.data;
    });
    this.$api("login.getCity").then((res) => {
      this.options1 = res.data;
    });
    this.$api("login.getType").then((res) => {
      this.options = res.data;
    });
    this.$api("account.getManage").then((res) => {
      this.checkList = res.data;
    });
  },
  methods: {
    handleCheckAllChange(val) {
      this.form.jynr = val ? this.checkList.map((e) => e.id) : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.checkList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.checkList.length;
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let p = {
            ...this.form,
            userpass: md5(this.form.userpass),
            sheng: this.value[0] || "",
            shi: this.value[1] || "",
            quxian: this.value[2] || "",
          };
          this.$api("account.updateInfo", p).then(() => {
            this.$message.success("提交资料成功");
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-input,
.el-select {
  width: 200px;
}
.form_wrap {
  margin-left: 154px;
  margin-top: 20px;
}
.btn {
  width: 76px;
  height: 30px;
  background: @themeColor;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
}

</style>
